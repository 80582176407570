import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
import MDBox from "@components/MDBox";
import MDTypography from "@components/MDTypography";
import LoadingSpinner from "@components/LoadingSpinner";
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

import AutocompleteBrazilianStates from "@components/AutocompleteBrazilianStates";

import "./custom_css.css";

function buildDetails(
  propertyDetails,
  formValues,
  errors,
  handleInputChange,
  handleStateChange,
  handleSubmit
) {
  return (
    <Container>
      <MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={6}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="none">
              Informações gerais
            </MDTypography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { marginTop: 2, width: "90%" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                id="title"
                value={formValues.title}
                onChange={handleInputChange}
                label="Nome"
                variant="outlined"
                required
                error={!!errors.title}
                helperText={errors.title}
              />
              <TextField
                id="number_of_rooms"
                value={formValues.number_of_rooms}
                onChange={handleInputChange}
                label="Número de Quartos"
                type="number"
                variant="outlined"
                required
                error={!!errors.number_of_rooms}
                helperText={errors.number_of_rooms}
              />
              <TextField
                id="description"
                className="custom-textarea"
                value={formValues.description}
                onChange={handleInputChange}
                label="Descrição"
                variant="outlined"
                multiline
                maxRows={7}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="none">
              Dados de endereço
            </MDTypography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { marginTop: 2, width: "90%" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                id="street_address"
                value={formValues.street_address}
                onChange={handleInputChange}
                label="Rua"
                variant="outlined"
                required
                error={!!errors.street_address}
                helperText={errors.street_address}
              />
              <TextField
                id="city_address"
                value={formValues.city_address}
                onChange={handleInputChange}
                label="Cidade"
                variant="outlined"
                required
                error={!!errors.city_address}
                helperText={errors.city_address}
              />
              <AutocompleteBrazilianStates
                value={formValues.state_address}
                onChange={handleStateChange}
                error={!!errors.state_address}
                helperText={errors.state_address}
              />
            </Box>
          </Grid>
        </Grid>
        <Box style={{ position: "fixed", bottom: 24, right: 32 }}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            <SaveIcon sx={{ mr: 1 }} />
            Salvar alterações
          </Fab>
        </Box>
      </MDBox>
    </Container>
  );
}

function GeneralInfoTab(props) {
  const { propertyId } = props;

  const detailsController = new DetailsController();

  const [propertyDetails, setPropertyDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchErrorMessage = "Ocorreu um erro";

  const [formValues, setFormValues] = useState({
    title: "",
    number_of_rooms: "",
    description: "",
    street_address: "",
    city_address: "",
    state_address: "",
  });

  const [errors, setErrors] = useState({
    title: "",
    number_of_rooms: "",
    description: "",
    street_address: "",
    city_address: "",
    state_address: "",
  });

  const getPropertyDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await detailsController.fetchPropertyDetails(propertyId);
      if (error) {
        setError(fetchErrorMessage);
      } else if (data) {
        setPropertyDetails(data);
        setFormValues(data);
      }
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropertyDetails();
  }, [propertyId]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleStateChange = (event, newValue) => {
    setFormValues({ ...formValues, state_address: newValue || "" });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.title = formValues.title ? "" : "Nome é obrigatório.";
    tempErrors.number_of_rooms =
      formValues.number_of_rooms && formValues.number_of_rooms > 0
        ? ""
        : "Número de quartos deve ser maior que 0.";
    tempErrors.street_address = formValues.street_address ? "" : "Rua é obrigatória.";
    tempErrors.city_address = formValues.city_address ? "" : "Cidade é obrigatória.";
    tempErrors.state_address = formValues.state_address ? "" : "Estado é obrigatório.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      await detailsController.updateProperty(propertyId, formValues);
      getPropertyDetails();
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return buildDetails(
    propertyDetails,
    formValues,
    errors,
    handleInputChange,
    handleStateChange,
    handleSubmit
  );
}

GeneralInfoTab.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

export default GeneralInfoTab;

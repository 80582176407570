import PropTypes from "prop-types";
import { Dropzone, FileMosaic } from "@files-ui/react";

// @mui/material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

function CompanyProfileInfo(props) {
  const {
    formValues,
    handleInputChange,
    handleSubmit,
    errors,
    updateLogoFile,
    updateWaterMarkFile,
    logoFile,
    waterMarkFile,
    clearLogoUrl,
    clearWaterMarkUrl,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
        <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
        <Box
          component="form"
          sx={{
            "& > :not(style)": { marginTop: 2, width: "90%" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Informações gerais
          </MDTypography>
          <TextField
            id="name"
            value={formValues.name}
            onChange={handleInputChange}
            label="Nome"
            variant="outlined"
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            id="website"
            value={formValues.website}
            onChange={handleInputChange}
            label="Website"
            variant="outlined"
            required
            error={!!errors.website}
            helperText={errors.website}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Logo
        </MDTypography>
        {formValues.logo_url ? (
          <Box position="relative">
            <img src={formValues.logo_url} alt="Logo" style={{ width: "100%" }} />
            <IconButton
              size="small"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={clearLogoUrl}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Dropzone
            onChange={updateLogoFile}
            value={logoFile}
            accept="image/png, image/jpg"
            maxFiles={1}
            maxFileSize={2 * 1024 * 1024}
            localization={"PT-pt"}
            config={{ localization: "PT-pt" }}
          >
            {logoFile.map((file) => (
              <FileMosaic {...file} preview />
            ))}
          </Dropzone>
        )}
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Box pl="35px">
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Marca dágua
          </MDTypography>
          {formValues.water_mark_url ? (
            <Box position="relative">
              <img src={formValues.water_mark_url} alt="Marca d'água" style={{ width: "100%" }} />
              <IconButton
                size="small"
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={clearWaterMarkUrl}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <Dropzone
              onChange={updateWaterMarkFile}
              value={waterMarkFile}
              accept="image/png, image/jpg"
              maxFiles={1}
              maxFileSize={2 * 1024 * 1024}
              localization={"PT-pt"}
            >
              {waterMarkFile.map((file) => (
                <FileMosaic {...file} preview />
              ))}
            </Dropzone>
          )}
        </Box>
      </Grid>
      <Fab
        variant="extended"
        color="primary"
        aria-label="save"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        onClick={handleSubmit}
      >
        <SaveIcon sx={{ mr: 1 }} />
        Salvar alterações
      </Fab>
    </Grid>
  );
}

// Setting default props for the CompanyProfileInfo
CompanyProfileInfo.defaultProps = {
  formValues: {
    name: "",
    website: "",
    logo_url: "",
    water_mark_url: "",
  },
  errors: {
    name: "",
    website: "",
  },
};

// Typechecking props for the CompanyProfileInfo
CompanyProfileInfo.propTypes = {
  formValues: PropTypes.shape({
    name: PropTypes.string,
    website: PropTypes.string,
    logo_url: PropTypes.string,
    water_mark_url: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
  updateLogoFile: PropTypes.func.isRequired,
  updateWaterMarkFile: PropTypes.func.isRequired,
  logoFile: PropTypes.array.isRequired,
  waterMarkFile: PropTypes.array.isRequired,
  clearLogoUrl: PropTypes.func.isRequired,
  clearWaterMarkUrl: PropTypes.func.isRequired,
};

export default CompanyProfileInfo;

// importing entities
import { ILoginResponseEntity } from "../../domain/entities/LoginResponse";

// importing usescases
import { Login } from "../usecases/Login";

// importing repositories
import { AuthRepository } from "../../domain/repositories/AuthRepository";

export class SigninController {
  private loginUC: Login;

  constructor() {
    const authRepository = new AuthRepository();

    this.loginUC = new Login(authRepository);
  }

  public async loginUser(email: string, password: string): Promise<ILoginResponseEntity> {
    try {
      const response = await this.loginUC.execute(email, password);
      return response;
    } catch (error) {
      console.error("Login error Controller:", error);
      throw new Error(error as string);
    }
  }
}

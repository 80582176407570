// Supabase client
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { IProfileEntity } from "../entities/Profile";
import { supabaseClient } from "App";

export interface IProfileRepository {
  getProfileData(profileId: number): Promise<PostgrestSingleResponse<IProfileEntity | null>>;
  updateProfileData(
    profileId: number,
    profileData: IProfileEntity
  ): Promise<PostgrestSingleResponse<IProfileEntity | null>>;
}

export class ProfileRepository implements IProfileRepository {
  async getProfileData(profileId: number): Promise<PostgrestSingleResponse<IProfileEntity | null>> {
    return await supabaseClient.from("users").select("*").eq("id", profileId).single();
  }

  async updateProfileData(
    profileId: number,
    profileData: IProfileEntity
  ): Promise<PostgrestSingleResponse<IProfileEntity | null>> {
    return await supabaseClient.from("users").update(profileData).eq("id", profileId);
  }
}

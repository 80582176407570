import React, { useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "@components/MDBox";
import MDTypography from "components/MDTypography";
import ImageViewer from "@components/ImageViewer";

// Importing controller
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

// Importing module components
import DeleteDialog from "../DeleteDialog";

const DownloadImage = ({ imageUrl }) => {
  let splitUrl = imageUrl.split("/");
  splitUrl = splitUrl[splitUrl.length - 1];
  splitUrl = splitUrl.split("?");

  const fileName = splitUrl[0];

  const handleDownload = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Falha ao fazer download da imagem:", error);
    }
  };

  return (
    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={handleDownload}>
      download
    </Icon>
  );
};

function renderPhotosList(photosList, deleteDialogRef, requestParentRebuild, openModal) {
  const handleClose = () => {};

  const handleDelete = async (mediaId, mediaPath) => {
    const detailsController = new DetailsController();
    try {
      const { data, error } = await detailsController.deleteMedia(mediaId, mediaPath);
      requestParentRebuild();
    } catch (error) {
      requestParentRebuild();
    } finally {
      requestParentRebuild();
    }
  };

  const openDeleteDialog = (mediaId, mediaPath) => {
    if (deleteDialogRef.current) {
      deleteDialogRef.current.openDialog(
        () => handleDelete(mediaId, mediaPath),
        handleClose,
        mediaId
      );
    }
  };

  if (photosList.length == 0) {
    return (
      <MDTypography variant="h5" fontWeight="medium" textTransform="none" mt={2}>
        Não há fotos cadastradas no momento :(
      </MDTypography>
    );
  }

  const listItems = photosList.map((photo, index) => {
    return (
      <Grid item xs={12} md={6} lg={4} key={"gird" + index}>
        <Card sx={{ height: "100%" }}>
          <MDBox pt={3} px={3}>
            <MDBox display="flex" justifyContent="right" alignItems="center">
              <MDBox color="text" mr="16px">
                <DownloadImage imageUrl={photo.media_url} />
              </MDBox>
              <MDBox color="text">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                  onClick={() => openDeleteDialog(photo.id, photo.media_path)}
                >
                  delete
                </Icon>
              </MDBox>
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDBox>
                <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
                  <CardMedia
                    src={photo.media_url}
                    component="img"
                    onClick={() => openModal(photo.media_url)}
                    sx={{
                      maxWidth: "100%",
                      width: "100%",
                      height: "12rem",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                      cursor: "pointer",
                    }}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    );
  });

  return listItems;
}

function ListPhotos(props) {
  const { photosList, requestParentRebuild, ...other } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const deleteDialogRef = useRef(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <Grid container spacing={3}>
      {renderPhotosList(photosList, deleteDialogRef, requestParentRebuild, openModal)}
      <DeleteDialog ref={deleteDialogRef} />
      {isModalOpen && <ImageViewer imageUrl={selectedImage} onClose={closeModal} />}
    </Grid>
  );
}

ListPhotos.defaultProps = {
  photosList: [{}],
};

ListPhotos.propTypes = {
  photosList: PropTypes.array.isRequired,
};

export default ListPhotos;

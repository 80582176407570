import { ICompanyEntity } from "../entities/CompanyEntity";
import { ICompanyRepository } from "../repositories/CompanyRepository";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetCompanyData {
  private companyRepository: ICompanyRepository;

  constructor(companyRepository: ICompanyRepository) {
    this.companyRepository = companyRepository;
  }

  async execute(userId: number): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    return this.companyRepository.getCompnayData(userId);
  }
}

import React, { useState, useEffect, useRef } from "react";

// Material Dashboard 2 React components
import MDBox from "@components/MDBox";
import MDTypography from "@components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "@examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@examples/Navbars/DashboardNavbar";

// Importing module components
import PropertiesList from "./pages/details/components/PropertiesList";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Importing controller
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

function Properties() {
  const [propertiesList, setPropertiesList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchErrorMessage = "Ocorreu um erro";

  const handleRebuild = () => {
    getAllProperties();
  };

  const getAllProperties = async () => {
    setLoading(true);
    const detailsController = new DetailsController();
    try {
      const { data, error } = await detailsController.getProperties();
      if (error) {
        setError(fetchErrorMessage);
      } else if (data) {
        setPropertiesList(data);
      }
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProperties();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h3" color="white">
            Propriedades
          </MDTypography>
        </MDBox>
        <MDBox mt={4.5}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <PropertiesList propertiesList={propertiesList} requestParentRebuild={handleRebuild} />
          )}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Properties;

class LocalStorageManager {
  private static key: string;

  public static setKey(key: string) {
    LocalStorageManager.key = key;
  }

  public static getValue(value: string): string | null {
    return localStorage.getItem(value);
  }

  public static setValue(keyName: string, newValue: string | null) {
    if (newValue) {
      localStorage.setItem(keyName, newValue);
    } else {
      localStorage.removeItem(LocalStorageManager.key);
    }
  }

  public static removeValue(keyName: string, newValue: string | null) {
    localStorage.removeItem(keyName);
  }

  public static clear() {
    localStorage.clear();
  }
}

export default LocalStorageManager;

import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

import routes from "./routes";
import ProtectedRoute from "./ProtectedRoute";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Supabase
import { createClient } from "@supabase/supabase-js";

/* TODO(@Marcus-Oliveira: Adicionar essas chaves em outro arquivo */
export const supabaseClient = createClient(
  "https://uvrzctijxcuuzwnlffir.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV2cnpjdGlqeGN1dXp3bmxmZmlyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkzNDczMzYsImV4cCI6MjAzNDkyMzMzNn0.bxZ9g5o2oEE0DS0TEGoKLUEhF8sqJaOYU13XH70yW6c"
);

const App = () => {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem("session");
    setIsAuthenticated(session != null);
    setIsLoading(false);
  }, []);

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {routes.map((route) => {
          if (route.protected) {
            return (
              <Route
                key={route.key}
                path={route.route}
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <route.component />
                  </ProtectedRoute>
                }
              />
            );
          }

          return <Route key={route.key} path={route.route} element={<route.component />} />;
        })}
        <Route path="*" element={<Navigate to="/properties" />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;

import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingSpinner: React.FC = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress sx={{ marginLeft: "auto", marginRight: "auto" }} />
    </Box>
  );
};

export default LoadingSpinner;

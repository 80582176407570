import { IPropertyRepository } from "../../../domain/repositories/PropertyRepository";
import { PropertyEntity } from "../../../domain/entities/PropertyEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetAllProperties {
  private propertyRepository: IPropertyRepository;

  constructor(propertyRepository: IPropertyRepository) {
    this.propertyRepository = propertyRepository;
  }

  async execute(): Promise<PropertyEntity> {
    return this.propertyRepository.getAllProperties();
  }
}

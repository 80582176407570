import { IPhotoRepository } from "../../../domain/repositories/PhotoRepository";
import { MediaEntity } from "../../../domain/entities/MediaEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class DeleteMedia {
  private photoRepository: IPhotoRepository;

  constructor(photoRepository: IPhotoRepository) {
    this.photoRepository = photoRepository;
  }

  async execute(propertyId: number, mediaPath: string): Promise<MediaEntity | null> {
    return this.photoRepository.deleteMedia(propertyId, mediaPath);
  }
}

import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Data
import UserDevicesTab from "modules/company/components/UserDevicesTab";
import UsersTab from "modules/company/components/UsersTab";
import CompanyProfileInfo from "modules/company/components/CompanyProfileInfo";

// Controller
import { CompanyController } from "./controller/CompanyController";

// Utils
import LocalStorageManager from "utils/LocalStorageManager";

// Getting Session Data
let sessionValue = JSON.parse(LocalStorageManager.getValue("session"));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CompanyPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchErrorMessage = "Ocorreu um erro";

  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [logoFile, setLogoFile] = useState([]);
  const updateLogoFile = (incommingFiles) => {
    setLogoFile(incommingFiles);
  };
  const [waterMarkFile, setWaterMarkFile] = useState([]);
  const updateWaterMarkFile = (incommingFiles) => {
    setWaterMarkFile(incommingFiles);
  };

  const [companyData, setCompanyData] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    website: "",
    logo_url: "",
    water_mark_url: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    website: "",
  });

  const clearLogoUrl = () => {
    setFormValues((prevValues) => ({ ...prevValues, logo_url: "" }));
  };

  const clearWaterMarkUrl = () => {
    setFormValues((prevValues) => ({ ...prevValues, water_mark_url: "" }));
  };

  const _getCompanyData = async () => {
    setLoading(true);
    try {
      const companyController = new CompanyController();
      const { data: successResponse, error: errorResponse } =
        await companyController.getCompanyData(sessionValue?.user?.id);
      if (error) {
        setError(fetchErrorMessage);
      } else if (successResponse) {
        updateLogoFile([]);
        updateWaterMarkFile([]);
        setCompanyData(successResponse);
        setFormValues({
          name: successResponse.name,
          website: successResponse.website,
          logo_url: successResponse.logo_url,
          water_mark_url: successResponse.water_mark_url,
        });
      }
      setLoading(false);
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await _getCompanyData();
    }
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validate = () => {
    let tempErrors = {
      name: "",
      website: "",
    };
    tempErrors.name = formValues.name ? "" : "Nome é obrigatório.";
    tempErrors.website = formValues.website ? "" : "Website é obrigatório.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const _updateCompanyData = async (companyId, companyData) => {
    setLoading(true);
    try {
      const companyController = new CompanyController();
      const { data: successResponse, error: errorResponse } = await companyController.updateCompany(
        companyId,
        formValues,
        {
          logoFile: logoFile.length > 0 ? logoFile[0].file : null,
          waterMarkFile: waterMarkFile.length > 0 ? waterMarkFile[0].file : null,
        }
      );
      if (error) {
        setError(fetchErrorMessage);
      } else if (successResponse) {
        setCompanyData({ ...companyData, ...formValues });
      }
      await _getCompanyData();
      setLoading(false);
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      await _updateCompanyData(companyData.id, companyData);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h3" color="white">
                  Empresa
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <AppBar position="static">
                  <MDBox pl={2} pr={2}>
                    <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                      <Tab
                        label="Informações gerais"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            info
                          </Icon>
                        }
                      />
                      <Tab
                        label="Usuários"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            person
                          </Icon>
                        }
                      />
                      <Tab
                        label="Dispositivos"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            smartphone
                          </Icon>
                        }
                      />
                    </Tabs>
                  </MDBox>
                </AppBar>
                <CustomTabPanel value={tabValue} index={0}>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <CompanyProfileInfo
                      formValues={formValues}
                      handleInputChange={handleInputChange}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      updateLogoFile={updateLogoFile}
                      updateWaterMarkFile={updateWaterMarkFile}
                      logoFile={logoFile}
                      waterMarkFile={waterMarkFile}
                      clearLogoUrl={clearLogoUrl}
                      clearWaterMarkUrl={clearWaterMarkUrl}
                    />
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <UsersTab companyId={companyData?.id} />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                  <UserDevicesTab companyId={companyData?.id} />
                </CustomTabPanel>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CompanyPage;

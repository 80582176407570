import React, { useState, useRef, useImperativeHandle, forwardRef, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "@components/MDButton";

// TODO(@Marcus-Oliveira): Remover essa interface e suas interferências
interface DeleteDialogProps {
  entityId: number;
}

export interface DeleteDialogRef {
  openDialog: (handleDelete: () => void, handleClose: () => void, entityId: number) => void;
}

const DeleteDialog = forwardRef<DeleteDialogRef, DeleteDialogProps>((props, ref) => {
  const [entityId, setEntityId] = useState(0);
  const [open, setOpen] = useState(false);
  const handleDeleteRef = useRef<() => void>(() => {});
  const handleCloseRef = useRef<() => void>(() => {});

  useImperativeHandle(ref, () => ({
    openDialog: (handleDelete, handleClose, entityId) => {
      handleDeleteRef.current = handleDelete;
      handleCloseRef.current = handleClose;
      setOpen(true);
      setEntityId(entityId);
    },
  }));

  const handleDeleteAction = useCallback(() => {
    handleDeleteRef.current();
    setOpen(false);
  }, [entityId]);

  const handleCloseAction = useCallback(() => {
    handleCloseRef.current();
    setOpen(false);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleCloseAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Deseja apagar esse imóvel?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem a certeza? Este imóvel e todas as fotos e vídeos associados serão perdidos para
          sempre.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton color="white" variant="contained" onClick={handleCloseAction}>
          Cancelar
        </MDButton>
        <MDButton color="error" variant="gradient" onClick={handleDeleteAction}>
          Apagar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
});

export default DeleteDialog;

import { IProfileEntity } from "../entities/Profile";
import { IProfileRepository } from "../repositories/ProfileRepository";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class UpdateProfileData {
  private profileRepository: IProfileRepository;

  constructor(profileRepository: IProfileRepository) {
    this.profileRepository = profileRepository;
  }

  async execute(
    profileId: number,
    profileData: IProfileEntity
  ): Promise<PostgrestSingleResponse<IProfileEntity | null>> {
    return this.profileRepository.updateProfileData(profileId, profileData);
  }
}

import { ILoginResponseEntity } from "modules/authentication/domain/entities/LoginResponse";
import { IAuthRepository } from "modules/authentication/domain/repositories/AuthRepository";

export class Login {
  private authRepository: IAuthRepository;

  constructor(authRepository: IAuthRepository) {
    this.authRepository = authRepository;
  }

  async execute(email: string, password: string): Promise<ILoginResponseEntity> {
    return await this.authRepository.login(email, password);
  }
}

import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { MediaEntity } from "../entities/MediaEntity";

import { IUploadResponse } from "../entities/UploadResponseEntity";

import axios, { AxiosError } from "axios";
import axiosInstance from "@utils/axiosconfig";

export interface IPhotoRepository {
  fetchPhotos(propertyId: number): Promise<MediaEntity[] | null>;
  uploadMedia(propertyId: number, file: File): Promise<IUploadResponse>;
  deleteMedia(mediaId: number, mediaPath: string): Promise<MediaEntity | null>;
}

export class PhotoRepository implements IPhotoRepository {
  async fetchPhotos(propertyId: number): Promise<MediaEntity[] | null> {
    try {
      const response = await axiosInstance.get<MediaEntity[]>(
        `properties/${propertyId}/medias?mediaType=image`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository error");
      }
    }
  }

  async uploadMedia(propertyId: number, file: File): Promise<IUploadResponse> {
    try {
      const formData = new FormData();
      formData.append("mediaFile", file);

      const response = await axiosInstance.post<IUploadResponse>(
        `properties/${propertyId}/medias?mediaType=image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository error");
      }
    }
  }

  async deleteMedia(mediaId: number): Promise<MediaEntity | null> {
    try {
      const propertyId = 20;
      const response = await axiosInstance.delete<MediaEntity>(
        `properties/${propertyId}/medias/${mediaId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository error");
      }
    }
  }
}

// importing entities
import { ICompanyEntity } from "modules/company/domain/entities/CompanyEntity";

// importing usescases
import { GetCompanyData } from "modules/company/domain/usecases/GetCompanyData";
import { UpdateCompanyData } from "modules/company/domain/usecases/UpdateCompanyData";
import { UploadCompanyLogo } from "modules/company/domain/usecases/UploadCompanyLogo";
import { UploadCompanyWaterMark } from "modules/company/domain/usecases/UploadCompanyWaterMark";
import { GetCompanyUsers } from "modules/company/domain/usecases/GetCompanyUsers";
import { InviteCompanyUser } from "modules/company/domain/usecases/InviteCompanyUser";

// importing repositories
import { CompanyRepository } from "modules/company/domain/repositories/CompanyRepository";

// importing supabase references
import { PostgrestSingleResponse, UserResponse } from "@supabase/supabase-js";

export class CompanyController {
  private getCompanyDataUC: GetCompanyData;
  private updateCompanyDataUC: UpdateCompanyData;
  private uploadCompanyLogoUC: UploadCompanyLogo;
  private uploadCompanyWaterMarkUC: UploadCompanyWaterMark;
  private getCompanyUsersUC: GetCompanyUsers;
  private inviteCompanyUserUC: InviteCompanyUser;

  constructor() {
    const companyRepository = new CompanyRepository();

    this.getCompanyDataUC = new GetCompanyData(companyRepository);
    this.updateCompanyDataUC = new UpdateCompanyData(companyRepository);
    this.uploadCompanyLogoUC = new UploadCompanyLogo(companyRepository);
    this.uploadCompanyWaterMarkUC = new UploadCompanyWaterMark(companyRepository);
    this.getCompanyUsersUC = new GetCompanyUsers(companyRepository);
    this.inviteCompanyUserUC = new InviteCompanyUser(companyRepository);
  }

  public async getCompanyData(
    userId: number
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    try {
      const response = await this.getCompanyDataUC.execute(userId);
      return response;
    } catch (error) {
      console.error("Error fetching companyData:", error);
      throw new Error("Could not fetch companyData");
    }
  }

  public async getRelatedUsers(
    companyId: number
  ): Promise<PostgrestSingleResponse<ICompanyEntity[] | null>> {
    try {
      const response = await this.getCompanyUsersUC.execute(companyId);
      return response;
    } catch (error) {
      console.error("Error fetching company users:", error);
      throw new Error("Could not fetch company users");
    }
  }

  public async inviteCompanyUser(email: string, companyId: number): Promise<UserResponse> {
    try {
      const response = await this.inviteCompanyUserUC.execute(email, companyId);
      return response;
    } catch (error) {
      console.error("Error fetching inviting users:", error);
      throw new Error("Could not fetch inviting users");
    }
  }

  public async getRelatedDevices(
    companyId: number
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    throw new Error("Method not implemented");
  }

  public async updateCompany(
    companyId: number,
    objectData: ICompanyEntity,
    files: { logoFile?: File; waterMarkFile?: File } = {}
  ): Promise<any> {
    const { logoFile, waterMarkFile } = files;

    try {
      let companyData: any = {
        name: objectData.name,
        website: objectData.website,
        logo_url: objectData.logo_url,
        water_mark_url: objectData.water_mark_url,
      };

      if (logoFile) {
        const uploadingFile = await this.uploadCompanyLogoUC.execute(logoFile);
        companyData = {
          ...companyData,
          logo_url: uploadingFile,
        };
      }

      if (waterMarkFile) {
        const uploadingFile = await this.uploadCompanyWaterMarkUC.execute(waterMarkFile);
        companyData = {
          ...companyData,
          water_mark_url: uploadingFile,
        };
      }

      const response = await this.updateCompanyDataUC.execute(companyId, companyData);
      return response;
    } catch (error) {
      console.error("Error updating Company data:", error);
      throw new Error("Error updating Company data");
    }
  }
}

import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const brazilianStates = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraíba",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins",
];

interface AutocompleteBrazilianStatesProps {
  value: string;
  onChange: (event: React.ChangeEvent<{}>, newValue: string | null) => void;
  error?: boolean;
  helperText?: string;
}

const AutocompleteBrazilianStates: React.FC<AutocompleteBrazilianStatesProps> = ({
  value,
  onChange,
  error = false,
  helperText = "",
}) => {
  return (
    <Autocomplete
      id="state_address"
      options={brazilianStates}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Estado"
          variant="outlined"
          required
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default AutocompleteBrazilianStates;

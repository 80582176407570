import { IVideoRepository } from "../../../domain/repositories/IVideoRepository";
import { MediaEntity } from "../../../domain/entities/MediaEntity";

export class GetVideos {
  private videoRepository: IVideoRepository;

  constructor(videoRepository: IVideoRepository) {
    this.videoRepository = videoRepository;
  }

  async execute(propertyId: number): Promise<MediaEntity[] | null> {
    return this.videoRepository.fetchVideos(propertyId);
  }
}

import { IProfileEntity } from "../entities/Profile";
import { IProfileRepository } from "../repositories/ProfileRepository";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetProfileData {
  private profileRepository: IProfileRepository;

  constructor(profileRepository: IProfileRepository) {
    this.profileRepository = profileRepository;
  }

  async execute(profileId: number): Promise<PostgrestSingleResponse<IProfileEntity | null>> {
    return this.profileRepository.getProfileData(profileId);
  }
}

import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
import InputMask from "react-input-mask";

// Material Dashboard 2 React components
import MDBox from "@components/MDBox";
import MDTypography from "@components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "@examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "modules/profile/components/Header";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Utils
import LocalStorageManager from "utils/LocalStorageManager";

// Supabase client
import { supabaseClient } from "App";
import { ProfileController } from "./controller/ProfileController";

function ProfilePage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchErrorMessage = "Ocorreu um erro";

  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  });

  const _getProfileData = async (userId) => {
    setLoading(true);
    try {
      const profileController = new ProfileController();
      const { data: successResponse, error: errorResponse } =
        await profileController.getProfileData(userId);
      if (error) {
        setError(fetchErrorMessage);
      } else if (successResponse) {
        setProfileData(successResponse);
      }
      setLoading(false);
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const _updateProfileData = async (userId, profileData) => {
    setLoading(true);
    try {
      const profileController = new ProfileController();
      const { data: successResponse, error: errorResponse } = await profileController.updateProfile(
        userId,
        profileData
      );
      if (error) {
        setError(fetchErrorMessage);
      } else if (successResponse) {
        setProfileData(successResponse);
      }
      setLoading(false);
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let sessionValue = JSON.parse(LocalStorageManager.getValue("session"));
    _getProfileData(sessionValue?.user?.id);
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setProfileData({ ...profileData, [id]: value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.first_name = profileData.first_name ? "" : "Nome é obrigatório.";
    tempErrors.last_name = profileData.last_name ? "" : "Sobrenome é obrigatório.";
    tempErrors.phone =
      profileData.phone && !/^\(\d{2}\)\s\d{4,5}-\d{4}$/.test(profileData.phone)
        ? "Telefone inválido."
        : "";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let sessionValue = JSON.parse(LocalStorageManager.getValue("session"));
      await _updateProfileData(sessionValue?.user?.id, profileData);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Header profileName={profileData.first_name}>
          <MDBox mt={1} mb={3}>
            <Container>
              <MDBox>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="none">
                      Informações do Perfil
                    </MDTypography>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { marginTop: 2, width: "90%" },
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        id="first_name"
                        value={profileData.first_name}
                        onChange={handleInputChange}
                        label="Nome"
                        variant="outlined"
                        required
                        error={!!errors.first_name}
                        helperText={errors.first_name}
                      />
                      <TextField
                        id="last_name"
                        value={profileData.last_name}
                        onChange={handleInputChange}
                        label="Sobrenome"
                        variant="outlined"
                        required
                        error={!!errors.last_name}
                        helperText={errors.last_name}
                      />
                      <InputMask
                        mask="(99) 99999-9999"
                        value={profileData.phone}
                        onChange={handleInputChange}
                      >
                        {() => (
                          <TextField
                            id="phone"
                            label="Telefone"
                            variant="outlined"
                            error={!!errors.phone}
                            helperText={errors.phone}
                          />
                        )}
                      </InputMask>
                    </Box>
                  </Grid>
                </Grid>
                <Box style={{ position: "fixed", bottom: 24, right: 32 }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <SaveIcon sx={{ mr: 1 }} />
                    Salvar alterações
                  </Fab>
                </Box>
              </MDBox>
            </Container>
          </MDBox>
        </Header>
      )}
    </DashboardLayout>
  );
}

export default ProfilePage;

import React, { useEffect } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

interface ImageViewerProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ imageUrl, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.modalContent}>
        <button style={styles.closeButton} onClick={onClose}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }}>close</Icon>
        </button>
        <img src={imageUrl} alt="Modal Content" style={styles.image} />
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
  },
  modalContent: {
    position: "relative",
    maxWidth: "90%",
    maxHeight: "90%",
    width: "100%",
    overflow: "auto",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    boxSizing: "border-box",
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    borderRadius: "8px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "black",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    fontSize: "20px",
    boxShadow: "0px 2px 4px rgba(255, 140, 0, 0.4)",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default ImageViewer;

// Supabase client
import { PostgrestSingleResponse, UserResponse } from "@supabase/supabase-js";
import { ICompanyEntity } from "../entities/CompanyEntity";
import { supabaseClient } from "App";
import { IUploadResponse } from "modules/properties/domain/entities/UploadResponseEntity";

export interface ICompanyRepository {
  getCompnayData(userId: number): Promise<PostgrestSingleResponse<ICompanyEntity | null>>;
  getCompnayUsers(companyId: number): Promise<PostgrestSingleResponse<ICompanyEntity[] | null>>;
  updateCompanyData(
    userId: number,
    companyData: ICompanyEntity
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>>;
  uploadLogo(file: File): Promise<String | null>;
  uploadWaterMark(file: File): Promise<String | null>;
  inviteCompanyUser(email: string, companyId: number): Promise<UserResponse>;
}

export class CompanyRepository implements ICompanyRepository {
  async getCompnayData(userId: number): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    return await supabaseClient.from("company").select("*").eq("user_id", userId).single();
  }

  async inviteCompanyUser(email: string, companyId: number): Promise<UserResponse> {
    // TODO(@Marcus-Oliveira): Move to backend side
    return await supabaseClient.auth.admin.inviteUserByEmail(email, {
      data: { role: "company_admin", companyId: companyId },
    });
  }

  async getCompnayUsers(
    companyId: number
  ): Promise<PostgrestSingleResponse<ICompanyEntity[] | null>> {
    return await supabaseClient.from("associated_user").select("*").eq("company_id", companyId);
  }

  async updateCompanyData(
    companyId: number,
    companyData: ICompanyEntity
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    return await supabaseClient.from("company").update(companyData).eq("id", companyId);
  }

  async uploadLogo(file: File): Promise<String | null> {
    let logoUrl: any = null;

    const uploadResponse: IUploadResponse = await supabaseClient.storage
      .from("new-public-bucket")
      .upload("company-logo.png", file, {
        cacheControl: "3600",
        upsert: false,
      });

    if (uploadResponse.error == null) {
      const getPublicUrlResponse = await supabaseClient.storage
        .from("new-public-bucket")
        .getPublicUrl(uploadResponse.data?.path!);

      logoUrl = getPublicUrlResponse.data.publicUrl;
    }
    return logoUrl;
  }

  async uploadWaterMark(file: File): Promise<String | null> {
    let logoUrl: any = null;

    const uploadResponse: IUploadResponse = await supabaseClient.storage
      .from("new-public-bucket")
      .upload("company-water-mark.png", file, {
        cacheControl: "3600",
        upsert: false,
      });

    if (uploadResponse.error == null) {
      const getPublicUrlResponse = await supabaseClient.storage
        .from("new-public-bucket")
        .getPublicUrl(uploadResponse.data?.path!);

      logoUrl = getPublicUrlResponse.data.publicUrl;
    }
    return logoUrl;
  }
}

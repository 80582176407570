import { ICompanyEntity } from "../entities/CompanyEntity";
import { ICompanyRepository } from "../repositories/CompanyRepository";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class UpdateCompanyData {
  private companyRepository: ICompanyRepository;

  constructor(companyRepository: ICompanyRepository) {
    this.companyRepository = companyRepository;
  }

  async execute(
    companyId: number,
    companyData: ICompanyEntity
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    return this.companyRepository.updateCompanyData(companyId, companyData);
  }
}

import { PropertyEntity } from "../entities/PropertyEntity";
import axios, { AxiosError } from "axios";
import axiosInstance from "@utils/axiosconfig";

export interface IPropertyRepository {
  getAllProperties(): Promise<PropertyEntity>;
  fetchData(propertyId: number): Promise<PropertyEntity | null>;
  updateProperty(propertyId: number, propertyData: PropertyEntity): Promise<PropertyEntity | null>;
  deletePropertyById(propertyId: number): Promise<PropertyEntity | null>;
}

export class PropertyRepository implements IPropertyRepository {
  async getAllProperties(): Promise<PropertyEntity> {
    try {
      const response = await axiosInstance.get<PropertyEntity>("properties");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository error");
      }
    }
  }

  async updateProperty(
    propertyId: number,
    propertyData: PropertyEntity
  ): Promise<PropertyEntity | null> {
    try {
      const response = await axiosInstance.put<PropertyEntity>(
        `properties/${propertyId}`,
        propertyData
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository Error");
      }
    }
  }

  async fetchData(propertyId: number): Promise<PropertyEntity | null> {
    try {
      const response = await axiosInstance.get<PropertyEntity>(`properties/${propertyId}`);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository Error");
      }
    }
  }

  async deletePropertyById(propertyId: number): Promise<PropertyEntity | null> {
    try {
      const response = await axiosInstance.delete<PropertyEntity>(`properties/${propertyId}`);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository Error");
      }
    }
  }
}

import { ICompanyEntity } from "../entities/CompanyEntity";
import { ICompanyRepository } from "../repositories/CompanyRepository";
import { PostgrestSingleResponse, UserResponse } from "@supabase/supabase-js";

export class InviteCompanyUser {
  private companyRepository: ICompanyRepository;

  constructor(companyRepository: ICompanyRepository) {
    this.companyRepository = companyRepository;
  }

  async execute(email: string, companyId: number): Promise<UserResponse> {
    return this.companyRepository.inviteCompanyUser(email, companyId);
  }
}

import axios, { AxiosError } from "axios";
import { ILoginResponseEntity } from "../entities/LoginResponse";
import { baseApi } from "config";

export interface IAuthRepository {
  login(email: string, password: string): Promise<ILoginResponseEntity>;
}

export class AuthRepository implements IAuthRepository {
  async login(email: string, password: string): Promise<ILoginResponseEntity> {
    try {
      const response = await axios.post<ILoginResponseEntity>(baseApi + "auth/login", {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Login Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Login error no repository");
      }
    }
  }
}

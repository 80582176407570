import { IPhotoRepository } from "../../../domain/repositories/PhotoRepository";
import { IUploadResponse } from "modules/properties/domain/entities/UploadResponseEntity";

export class UploadMedia {
  private photoRepository: IPhotoRepository;

  constructor(photoRepository: IPhotoRepository) {
    this.photoRepository = photoRepository;
  }

  async execute(propertyId: number, file: File): Promise<IUploadResponse> {
    return this.photoRepository.uploadMedia(propertyId, file);
  }
}

import { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// @mui material components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Importing Tabs
import PhotosTab from "modules/properties/pages/details/components/tabs/PhotosTab";
import VideosTab from "modules/properties/pages/details/components/tabs/VideosTab";
import GeneralInfoTab from "modules/properties/pages/details/components/tabs/GeneralInfoTab";

function PropertyDetails() {
  const { propertyId } = useParams();

  // Tab Configuration
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h3" color="white">
                  Detalhes da Propriedade
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <AppBar position="static">
                  <MDBox pl={2} pr={2}>
                    <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                      <Tab
                        label="Informações gerais"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            info
                          </Icon>
                        }
                      />
                      <Tab
                        label="Fotos"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            add_photo_alternate
                          </Icon>
                        }
                      />
                      <Tab
                        label="Videos"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            play_circle
                          </Icon>
                        }
                      />
                    </Tabs>
                    <CustomTabPanel value={tabValue} index={0}>
                      <GeneralInfoTab propertyId={propertyId} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                      <PhotosTab propertyId={propertyId} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={2}>
                      <VideosTab propertyId={propertyId} />
                    </CustomTabPanel>
                  </MDBox>
                </AppBar>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default PropertyDetails;

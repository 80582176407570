// Importing React components
import { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "@components/MDBox";
import MDTypography from "components/MDTypography";

// Importing controller
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

// Importing module components
import DeleteDialog from "../DeleteDialog/index";

function PropertiesList(props) {
  const { propertiesList, requestParentRebuild, ...other } = props;
  const deleteDialogRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const errorMessage = "Ocorreu um erro";

  const handleDelete = async (propertyId) => {
    const detailsController = new DetailsController();
    try {
      setLoading(true);
      const { data, error } = await detailsController.deletePropertyByID(propertyId);
      if (error) {
        setError(errorMessage);
      } else if (data == null) {
        requestParentRebuild();
      }
    } catch (error) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {};

  const openDeleteDialog = (propertyId) => {
    if (deleteDialogRef.current) {
      deleteDialogRef.current.openDialog(() => handleDelete(propertyId), handleClose, propertyId);
    }
  };

  if (propertiesList.length == 0) {
    return (
      <MDTypography variant="h5" fontWeight="medium" textTransform="none" mt={2}>
        Não há propriedades cadastradas no momento
      </MDTypography>
    );
  }

  const listItems = propertiesList.map((property, index) => (
    <Grid item xs={12} md={6} lg={4} key={index + 1}>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <Link
              to={{
                pathname: `/properties/${property.id}`,
              }}
            >
              <MDTypography variant="h6" gutterBottom>
                {property.title}
              </MDTypography>
            </Link>
            <MDBox color="text">
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
                onClick={() => openDeleteDialog(property.id)}
              >
                delete
              </Icon>
            </MDBox>
          </MDBox>
          <MDBox mt={2} mb={2}>
            <MDBox>
              <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
                <Link
                  to={{
                    pathname: `/properties/${property.id}`,
                  }}
                >
                  <CardMedia
                    src={property.cover_photo_url}
                    component="img"
                    sx={{
                      maxWidth: "100%",
                      width: "100%",
                      height: "12rem",
                      margin: 0,
                      boxShadow: ({ boxShadows: { md } }) => md,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </Link>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  ));

  return (
    <Grid container spacing={3}>
      {listItems}
      <DeleteDialog ref={deleteDialogRef} />
    </Grid>
  );
}

export default PropertiesList;

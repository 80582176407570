import { useState, useEffect } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui/material components
import Fab from "@mui/material/Fab";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import MDButton from "@components/MDButton";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Importing controller
import { CompanyController } from "modules/company/pages/controller/CompanyController";

function UsersTab(props) {
  const { companyId, ...other } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");

  const fetchErrorMessage = "Ocorreu um erro";

  let columns = [
    { Header: "name", accessor: "name", width: "45%", align: "left" },
    { Header: "function", accessor: "function", align: "left" },
  ];
  let response = null;

  const [newRows, setNewRows] = useState([]);

  const companyController = new CompanyController();

  async function _getUsersData(companyId) {
    setLoading(true);
    try {
      const { data: dataUD, error: errorUD } = await companyController.getRelatedUsers(companyId);
      if (errorUD) {
        setError(fetchErrorMessage);
      } else if (dataUD) {
        setNewRows(formatRowData(dataUD));
      }
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await _getUsersData(companyId);
    }
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setRole("user");
    setEmailError("");
    setRoleError("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSave = async () => {
    let valid = true;
    if (!email) {
      setEmailError("O campo email é obrigatório.");
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Por favor, insira um email válido.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!role) {
      setRoleError("O campo função é obrigatório.");
      valid = false;
    } else {
      setRoleError("");
    }

    if (valid) {
      setOpen(false);
      setLoading(true);
      try {
        const { data: dataUD, error: errorUD } = await companyController.inviteCompanyUser(
          email,
          companyId
        );
        if (errorUD) {
          setError(fetchErrorMessage);
        } else if (dataUD) {
          setNewRows(formatRowData(dataUD));
        }
      } catch (error) {
        setError(fetchErrorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <DataTable
        table={{ columns: columns, rows: newRows }}
        isSorted={true}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        onClick={handleClickOpen}
      >
        <PersonAddIcon sx={{ mr: 1 }} />
        Convidar usuário
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Convidar usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para convidar um novo usuário, por favor, preencha o email e selecione a função.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            select
            margin="dense"
            id="role"
            label="Função"
            fullWidth
            variant="standard"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            error={!!roleError}
            helperText={roleError}
          >
            <MenuItem value="user">Usuário comum</MenuItem>
            <MenuItem value="admin">Administrador</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <MDButton color="white" variant="contained" onClick={handleClose}>
            Cancelar
          </MDButton>
          <MDButton color="error" variant="gradient" onClick={handleSave}>
            Salvar
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function formatRowData(rowData) {
  return rowData.map((row) => {
    return {
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.user_name}
        </MDTypography>
      ),
      function: _buildRoleInCompanyComponet(row.role),
    };
  });
}

function _buildRoleInCompanyComponet(role) {
  let roleInCompanyComponent;

  if (role === "company_admin") {
    roleInCompanyComponent = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Administrador
      </MDTypography>
    );
  } else {
    roleInCompanyComponent = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Usuário comum
      </MDTypography>
    );
  }

  return roleInCompanyComponent;
}

// Typechecking props for the UsersTab
UsersTab.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default UsersTab;

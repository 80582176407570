// importing entities
import { IProfileEntity } from "modules/profile/domain/entities/Profile";

// importing usescases
import { GetProfileData } from "modules/profile/domain/usecases/GetProfileData";
import { UpdateProfileData } from "modules/profile/domain/usecases/UpdateProfileData";

// importing repositories
import { ProfileRepository } from "modules/profile/domain/repositories/ProfileRepository";

// importing supabase references
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class ProfileController {
  private getProfileDataUC: GetProfileData;
  private updateProfileDataUC: UpdateProfileData;

  constructor() {
    const profileRepository = new ProfileRepository();

    this.getProfileDataUC = new GetProfileData(profileRepository);
    this.updateProfileDataUC = new UpdateProfileData(profileRepository);
  }

  public async getProfileData(
    profileId: number
  ): Promise<PostgrestSingleResponse<IProfileEntity | null>> {
    try {
      const response = await this.getProfileDataUC.execute(profileId);
      return response;
    } catch (error) {
      console.error("Error fetching ProfileData:", error);
      throw new Error("Could not fetch ProfileData");
    }
  }

  public async updateProfile(profileId: number, objectData: IProfileEntity): Promise<any> {
    try {
      const profileData: any = {
        first_name: objectData.first_name,
        last_name: objectData.last_name,
        phone: objectData.phone,
      };
      const response = await this.updateProfileDataUC.execute(profileId, profileData);
      return response;
    } catch (error) {
      console.error("Error updating Profile:", error);
      throw new Error("Error updating Profile");
    }
  }
}

import axios from "axios";
import { baseApi } from "config";

// Utils
import LocalStorageManager from "@utils/LocalStorageManager";

// Getting Session Data
let sessionValue = JSON.parse(LocalStorageManager.getValue("session")!);

const axiosInstance = axios.create({
  baseURL: baseApi,
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token: string = sessionValue.accessToken;
    if (token && config.url !== "/auth/login") {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

import { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import DataTable from "examples/Tables/DataTable";

// Supabase client
import { supabaseClient } from "App";

function UserDevicesTab({ companyId }) {
  let columns = [
    { Header: "nome", accessor: "name", width: "30%", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "modelo", accessor: "model", align: "left" },
    { Header: "último acesso", accessor: "lastSession", align: "left" },
    { Header: "status", accessor: "status", align: "left" },
  ];
  let response = null;

  const [newRows, setNewRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      response = await _getUserDeviceData(companyId);
      setNewRows(formatRowData(response));
    }
    fetchData();
  }, []);

  return (
    <DataTable
      table={{ columns: columns, rows: newRows }}
      isSorted={true}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
}

async function _getUserDeviceData(companyId) {
  /* TODO(@Marcus-Oliveira: Pegando os dados da tabela UserDevice. REFATORAR em outra camada */
  let finalData = [];
  if (companyId != null) {
    const { data: dataUD, error: errorUD } = await supabaseClient
      .from("user_device")
      .select()
      .eq("company_id", companyId);
    finalData = dataUD;
  }
  return finalData;
}

function formatRowData(rowData) {
  return rowData.map((row) => ({
    name: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {row.user_name}
      </MDTypography>
    ),
    email: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {row.user_email}
      </MDTypography>
    ),
    model: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {row.device_name}
      </MDTypography>
    ),
    lastSession: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {new Date(row.created_at).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </MDTypography>
    ),
    status: _buildAccessComponentComponet(row.status),
  }));
}

function _buildAccessComponentComponet(deviceStatus) {
  let accessComponent;
  if (deviceStatus === "blocked") {
    accessComponent = (
      <MDBox ml={-1}>
        <MDBadge badgeContent="bloaqueado" color="dark" variant="gradient" size="sm" />
      </MDBox>
    );
  } else {
    accessComponent = (
      <MDBox ml={-1}>
        <MDBadge badgeContent="acesso total" color="success" variant="gradient" size="sm" />
      </MDBox>
    );
  }

  return accessComponent;
}

// Typechecking props for the UserDevicesTab
UserDevicesTab.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default UserDevicesTab;

import { IUploadResponse } from "modules/properties/domain/entities/UploadResponseEntity";
import { ICompanyRepository } from "../repositories/CompanyRepository";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class UploadCompanyWaterMark {
  private companyRepository: ICompanyRepository;

  constructor(companyRepository: ICompanyRepository) {
    this.companyRepository = companyRepository;
  }

  async execute(file: File): Promise<String | null> {
    return this.companyRepository.uploadWaterMark(file);
  }
}

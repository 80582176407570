import { ICompanyEntity } from "../entities/CompanyEntity";
import { ICompanyRepository } from "../repositories/CompanyRepository";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetCompanyUsers {
  private companyRepository: ICompanyRepository;

  constructor(companyRepository: ICompanyRepository) {
    this.companyRepository = companyRepository;
  }

  async execute(companyId: number): Promise<PostgrestSingleResponse<ICompanyEntity[] | null>> {
    return this.companyRepository.getCompnayUsers(companyId);
  }
}

import { IVideoRepository } from "./IVideoRepository";
import { MediaEntity } from "../entities/MediaEntity";

import axios, { AxiosError } from "axios";
import axiosInstance from "@utils/axiosconfig";

export class VideoRepository implements IVideoRepository {
  async fetchVideos(propertyId: number): Promise<MediaEntity[] | null> {
    try {
      const response = await axiosInstance.get<MediaEntity[]>(
        `properties/${propertyId}/medias?mediaType=video`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data["message"]);
        throw new Error(error.response?.data["message"]);
      } else {
        console.error("Unexpected error:", error);
        throw new Error("Repository error");
      }
    }
  }
}

import React, { useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import ReactPlayer from "react-player/lazy";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "@components/MDBox";
import MDTypography from "components/MDTypography";

// Importing controller
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

// Importing module components
import DeleteDialog from "../DeleteDialog";

const DownloadVideo = ({ videoUrl }) => {
  let splitUrl = videoUrl.split("/");
  splitUrl = splitUrl[splitUrl.length - 1];
  splitUrl = splitUrl.split("?");

  const fileName = splitUrl[0];

  const handleDownload = async () => {
    try {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Falha ao fazer download do video:", error);
    }
  };

  return (
    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={handleDownload}>
      download
    </Icon>
  );
};

function renderVideosList(videosList, deleteDialogRef, requestParentRebuild) {
  const handleClose = () => {};

  const handleDelete = async (mediaId) => {
    const detailsController = new DetailsController();
    try {
      const { data, error } = await detailsController.deleteMedia(mediaId);
      requestParentRebuild();
    } catch (error) {
      requestParentRebuild();
    } finally {
      requestParentRebuild();
    }
  };

  const openDeleteDialog = (mediaId) => {
    if (deleteDialogRef.current) {
      deleteDialogRef.current.openDialog(() => handleDelete(mediaId), handleClose, mediaId);
    }
  };

  if (videosList.length == 0) {
    return (
      <MDTypography variant="h5" fontWeight="medium" textTransform="none" mt={2}>
        Não há videos cadastradas no momento :(
      </MDTypography>
    );
  }

  const listItems = videosList.map((video, index) => {
    return (
      <Grid item xs={12} md={6} lg={4} key={"gird" + index}>
        <Card sx={{ height: "100%" }}>
          <MDBox pt={3} px={3}>
            <MDBox display="flex" justifyContent="right" alignItems="center">
              <MDBox color="text" mr="16px">
                <DownloadVideo videoUrl={video.media_url} />
              </MDBox>
              <MDBox color="text">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                  onClick={() => openDeleteDialog(video.id)}
                >
                  delete
                </Icon>
              </MDBox>
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDBox>
                <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
                  <ReactPlayer url={video.media_url} controls={true} width="100%" />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    );
  });

  return listItems;
}

function ListVideos(props) {
  const { videosList, requestParentRebuild, ...other } = props;
  const deleteDialogRef = useRef(null);

  return (
    <Grid container spacing={3}>
      {renderVideosList(videosList, deleteDialogRef, requestParentRebuild)}
      <DeleteDialog ref={deleteDialogRef} />
    </Grid>
  );
}

ListVideos.defaultProps = {
  videosList: [{}],
};

ListVideos.propTypes = {
  videosList: PropTypes.array.isRequired,
};

export default ListVideos;
